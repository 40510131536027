import React from 'react'
import { graphql, Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../templates/layout'

const Container = styled.div`
  margin-top: ${ props => props.theme.pagePadding };
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media ${ props => props.theme.device.sm } {
    margin-top: ${ props => props.theme.pagePaddingMobile };
  }

  section {
    margin-bottom: 5.75rem;

    @media ${ props => props.theme.device.sm } {
      margin-top: 4.375rem;
    }
  }

  p {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;

    &:first-child {
      margin-top: 1.5rem;
    }

    @media ${ props => props.theme.device.sm } {
      margin-top: 1.56rem;
      margin-bottom: 1.56rem;

      &:first-child {
        margin-top: 0.75rem;
      }
    }
  }
`

const SectionSmall = styled.section`
  h1 {
    margin-bottom: 1.5rem;

    @media ${ props => props.theme.device.sm } {
      margin-bottom: 0.75rem;
    }
  }

  h2 {
    margin-top: 2.5rem;

    @media ${ props => props.theme.device.sm } {
      margin-top: 1.25rem;
    }
  }

  h3 {
    font-family: 'Shapiro-55Middle';
    font-size: 1.5rem;
    line-height: 1.33;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    @media ${ props => props.theme.device.sm } {
      font-size: 0.75rem;
      margin-top: 0.375rem;
      margin-bottom: 0.375rem;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.33;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    &:first-child {
      margin-top: 0;
    }

    @media ${ props => props.theme.device.sm } {
      font-size: 0.75rem;
      margin-top: 0.375rem;
      margin-bottom: 0.375rem;
    }
  }
`

const ContactPage = ({ data: { datoCmsContact: { contact, copyright, privacyPolicy } }, location, pageContext }) => {
  return (
    <Layout location={location} pageContext={pageContext}>
      <Container>
        <section>
          <h1>Contact</h1>
          <div dangerouslySetInnerHTML={{ __html: contact }}/>
        </section>
        <section>
          <h1>Copyright</h1>
          <div dangerouslySetInnerHTML={{ __html: copyright }}/>
        </section>
        <SectionSmall>
          <h1 id="privacy-policy">Privacy Policy / Datenschutz</h1>
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}/>
        </SectionSmall>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    datoCmsContact {
      contact
      copyright
      privacyPolicy
    }
  }
`

export default ContactPage
